@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Raleway:wght@600&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@600&family=Ubuntu&display=swap');

.download-container {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #19273B;
    padding-top: 20px;
    padding-bottom: 50px;
}
.title-container {
    color: white;
    border-bottom: 0.1px solid;
    margin-bottom: 15px;
}
.title-container h1 {
    letter-spacing: 2px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 10px;
}
.text-container {
    width: 60%;
    text-align: left;
}
.text-container p {
    color: white;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    font-size: 22px;
}
.btn-view-all-container {
    /* margin-top: 40px; */
    text-align: center;
}
.btn {
    width: 190px;
    height: 50px;
    margin: 5px!important;
}
/* PHONES */
@media only screen and (max-width: 600px) {
    .download-container {
        margin-top: 35px;
    }
    .title-container {
        color: white;
        border-bottom: 0.1px solid;
        margin-bottom: 15px;
    }
    .title-container h1 {
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
        font-size: 27px;
        margin-bottom: 10px;
    }
    .text-container {
        width: 80%;
        text-align: center;
    }
    .text-container p {
        color: white;
        font-family: 'Jost', sans-serif;
        font-weight: 400;
        font-size: 15px;
    }
    .btn {
        width: 80%;
    }
}
