/* PHONES */

.products {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.product-container {
    width: 97%;
    margin: 80px auto;
}
a {
   text-decoration: none; 
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #040F28;
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #ffffff transparent #ffffff transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}
.product-btns{
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: auto;
}
.product-btns a{
    margin: 0px 7px;
}
.btn {
    width: 190px;
    height: 50px;
    margin: 5px!important;
}
@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 1390px) {
    .product-container {
        width: 1120px!important;
        margin: 80px auto!important;
    }
    .product-btns{
        width: 1120px!important;

    }
}
@media all and (max-width:1390px) and (min-width:901px) {
    .product-container {
        width: 80%!important;
        margin: 80px auto!important;
    }
    .products {
        padding: 10px;
    }
    .product-btns{
        width: 80%!important;
    }
}

@media only screen and (max-width: 900px) {
    .product-container {
        margin: 40px auto!important;
        width: 65%;
    }
    .product-btns{
        width: 58%;
    }
    .btn {
        width: 141px;
        height: 42px;
    }
    .btn-view-all-container {
        margin-top: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .product-container {
        width: 97%;
        margin: 40px auto!important;
        padding: 5px;
    }
    .product-btns{
        width: 94%;
    }
}
