/* ALL DEVICES  */

.header {
    background-color: #040F28;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.navigation{
    display: flex;
    margin-bottom: 10px;
    width: 80%;
    max-width: 600px;
    justify-content: space-evenly;
    max-width: 600px;
}
.navLink{
    padding: 0px 10px;
    color: white!important;
}
.navLink:hover{
    transform: scale(1.1);
    transition: ease 0.3s;
    cursor: pointer;
}
/* MEDIUM DEVICES */

@media only screen and (min-width: 993px) {
    .header {
        height: 180px;
    }
    .logo{
        width: 120px
    }
}

/* MEDIUM DEVICES */

@media only screen and (max-width: 992px) {
    .header {
        height: 160px;
    }
    .logo{
        width: 100px;
    }
}
/* PHONES */

@media only screen and (max-width: 600px) {
    .header {
        height: 140px;
    }
    .logo{
        width: 75px;
    }
}