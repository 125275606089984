.banner {
    background-image: url('../../../public/img/banner.png');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 40%;
}

/* MEDIUM DEVICES */

@media only screen and (max-width: 992px) {
    .banner {
        padding-bottom: 100%;
        background-image: url('../../../public/img/banner-mobile.png');
    }
}

/* PHONES */

@media only screen and (max-width: 600px) {
    .banner {
        padding-bottom: 100%;
        background-image: url('../../../public/img/banner-mobile.png');
    }
}