#root {
    width: 100%;
}
body {
    height: 100vh;
    background-color: #040F28;
    display: flex;
    justify-content: center;
    width: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}