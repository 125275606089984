.form-container {
  margin-top: 20px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Jost', sans-serif;
  font-weight: 900;
  font-size: 16px;
}

.form-container input {
  border-radius: 4px;
  margin-bottom: 15px;
  border: none;
  height: 30px;

  outline: none;
}

.form-input {
  margin-top: 4px;
  color: #fff;
  font-size: 16px;
  padding: 4px 6px;
}

.input-radio {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-radio label{
  letter-spacing: 1px;
}
.input-radio input {
  width: 15px;
  margin: 0px;
  padding: 0px;
  margin-left: 6px;
}
.input-radio input:nth-child(2){
  margin-right: 50px;
}

.submit-btn {
  margin-top: 13px;
  background-color: #2F80ED !important;
  height: 50px;
  width: 354px;
  margin-bottom: 40px;
  color: white;
  font-family: 'Jost', sans-serif;
  letter-spacing: 2px;
  font-weight: 800 !important;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: #2b5c9b !important;
}
.title {
  font-size: 28px;
  text-align: left;
}
.date-container {
  border: 1px solid #667;
  border-radius: 5px;
  width: 350px;
  margin: 20px 0px;
  height: 130px;
}

h2 {
  margin: -12px 15px 10px;
  background: #040F28;
  width: 42px;
  padding: 0 8px;
  color: white;
  font-family: 'Jost', sans-serif;
  font-weight: 900;
  font-size: 16px;
}
.input-radio-align {
  display: flex;
  align-items: center;
}
.date-input-container {
  padding-left: 15px;
  padding-right: 15px;
}
.date-input-container input:nth-child(1) {
  width: 100%;
}