@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@600&family=Ubuntu&display=swap');


.footer-container {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #040F28;
    color: white;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    font-size: 14px;
}
.contact-container {
    position: absolute;
    top: 60%;
    left: 20%;
    transform: translate(-50%, -50%);
    
}
.contact-container p {
    margin: 3px;
    font-weight: 500;
    font-size: 17px;
}
.copyright {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
/* PHONES */

@media only screen and (max-width: 600px) {
    .copyright {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
    
    }
    .contact-container {
        text-align: center;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }
}
