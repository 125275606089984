@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@600&family=Ubuntu&display=swap');

.schedule-container {
    display: flex;
    flex-direction: column;
    height: 280px;
    background-color: white;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
}

.schedule-title {
    color: #18345C;
    width: 60%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 42px;
}

.schedule-btn {
    background-color: #EB5757!important;
    height: 50px;
    width: 200px;
    margin-bottom: 40px;   
}
.schedule-btn:hover {
    background-color: #f93c3c!important;
}

.modal-container {
    width: 90%;
    max-width: 505px;
    min-height: 360px;
    background-color: #040F28;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    color: white;
    align-items: center;
}
.logo {
    width: 120px;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.form-container {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: 'Jost', sans-serif;
    font-weight: 900;
    align-items: center;
    font-size: 16px;
}

.form-container input{
    border-radius: 4px;
    margin-bottom: 13px;
    border: none;
    height: 30px;
    width: 272px;
    outline: none;
}

.submit-btn {
    margin-top: 25px !important;
    background-color: #2F80ED!important;
    height: 40px;
    width: 285px;
    margin-bottom: 40px;   
    color: white;
    font-family: 'Jost', sans-serif;
    letter-spacing: 2px;
    font-weight: 800!important;
    font-size: 16px;
}
.submit-btn:hover {
    background-color: #2b5c9b!important;
}
.close-modal {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 14px;
    height: 14px;
    border: none;
    background-size: cover;
    cursor: pointer;
    background-color: transparent;
    background-image: url('../../../public/img/x-button.png')
}

.input-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}
.input-radio label{
    letter-spacing: 1px;
}
.input-radio input {
    width: 15px;
    margin: 0px;
    padding: 0px;
    margin-left: 6px;
}
.input-radio input:nth-child(2){
    margin-right: 50px;
}

.form-description {
    text-align: center;
    align-self: center;
    justify-self: center;
    font-weight: 500;
}

.form-input {
    margin-top: 26px;
    padding: 6px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.close-modal {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 14px;
    height: 14px;
    border: none;
    background-size: cover;
    cursor: pointer;
    background-color: transparent;
    background-image: url('../../../public/img/x-button.png')
}

.container {
    display: flex;
    flex-direction: column;
}