.brands-container {
    background-color: #19273B;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.mobile {
    display: none;
}
.desk {
    display: block;
}
.brands-logos {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 127px;
    max-height: 160px;
}

.brands-logos a {
    padding: 20px;
}

.hover-image {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.brands-logos img:hover {
    transition: 2s ease;
}

.img-logos {
    width: 90%
}

@media only screen and (min-width: 900px) {
    .brands-logos {
        margin: 0 32px;
    }
}

/* PHONES */
@media only screen and (max-width: 600px) {
    .desk {
        display: none;
    }
    .mobile {
        display: block;
    }
    .brands-logos {
        min-height: 50px;
        max-height: 80px;
    }

    .img-logos {
        width: 70px
            /* width: auto; */
    }
}
